@import 'colors.scss';

$chevron-gray: #6c757d;
$border-blue: #135aa9;
$border-gray: #d7d8d9;

.p-autocomplete {
    width: 100%;
}
input.p-autocomplete-input {
    height: 41px !important;
    border-radius: 6px;
    border-right: none;
}

// custom button to look like inside the input
.p-autocomplete-input:enabled:focus {
    box-shadow: none;
}
.p-autocomplete-input:enabled:hover ~ button.p-autocomplete-dropdown,
.p-autocomplete-input:enabled:focus ~ button.p-autocomplete-dropdown,
.p-autocomplete-input:has(+ button.p-autocomplete-dropdown:hover) {
    border-color: $border-blue;
}
button.p-autocomplete-dropdown {
    background-color: transparent;
    border: 1px solid $border-gray;
    border-left: none;
    border-radius: 6px;
}
button.p-autocomplete-dropdown:hover,
button.p-autocomplete-dropdown:focus {
    background-color: transparent !important;
    border-left: none;
    box-shadow: none;
}
button .p-icon-wrapper {
    color: $chevron-gray;
}

.p-autocomplete-panel {
    max-height: 500px !important;
    li {
        font-size: 13px;
        line-height: 20px;
    }
    li.p-autocomplete-item-group {
        font-weight: 700;
        color: $gray-step;
    }
    li.p-autocomplete-item {
        font-weight: 400;
        padding-left: 35px !important;
        color: $main;
    }
}
p-autocomplete.p-inputwrapper-filled ~ label,
p-autocomplete.p-inputwrapper-focus ~ label {
    color: $info;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
}
